<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container form-cont">
        <img class="logo-login" src="../assets/logo-oom.png" alt="Logo" />
        <p class="title is-4">Se Connecter</p>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <form action="#" @submit.prevent="submit">
          <div class="form-group">
            <div class="container-sub">
              <input
                placeholder="Email"
                id="email"
                type="email"
                class="input is-large"
                name="email"
                value
                required
                autofocus
                v-model="form.email"
              />
            </div>
          </div>

          <div class="container">
            <div class="container-sub">
              <input
                placeholder="Mot de passe"
                id="password"
                type="password"
                class="input is-large"
                name="password"
                required
                v-model="form.password"
              />
            </div>
          </div>

          <div class="container-sub">
            <button
              id="submitbtn"
              type="submit"
              class="button is-large is-link is-light is-fullwidth"
              v-bind:class="{ 'is-loading': loading }"
            >
              CONNEXION
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
// import store from "../store";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          //   this.$router.replace({ name: "Today" });
          // console.log("ONAUTH STATE: " + store.state.user.loggedIn);
          setTimeout(() => {
            // if (store.state.user.loggedIn) {
            this.loading = false;
            this.$router.replace({ path: "/" });
            // }
          }, 500);
        })
        .catch((err) => {
          this.loading = false;
          this.error = err.message;
        });
    },
  },
};
</script>

<style scoped>
.logo-login {
  width: 150px;
  margin-bottom: 100px;
}
.hero.is-fullheight {
  min-height: 50vh !important;
  height: calc(100vh - 70px) !important;
}
.form-cont {
  max-width: 500px;
}
.container-sub {
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .form-cont {
    max-width: 80vw;
  }
}
</style>